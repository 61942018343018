/** Remove leading slash from a string */
export function trimLeadingSlash(string: string) {
  return string.replace(/^\//, "");
}

/** Remove trailing slash from a string */
export function trimTrailingSlash(string: string) {
  return string.replace(/\/$/, "");
}

/** Remove leading and trailing slashes from a string */
export function trimSlashes(string: string) {
  return trimTrailingSlash(trimLeadingSlash(string));
}

/** Truncate a string to a maximum length, adding a suffix if truncated */
export function truncate(string: string, maxLength: number, suffix = "...") {
  if (string.length <= maxLength) {
    return string;
  }
  return string.slice(0, maxLength) + suffix;
}

/** Transform a string into a slug */
export function slugify(string: string) {
  return string
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/^-|-$/g, "");
}
